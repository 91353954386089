






























import { SfButton, SfInput } from '@storefront-ui/vue';
import {
  computed,
  ref,
  defineComponent,
} from '@nuxtjs/composition-api';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useCart } from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: 'CouponCode',
  components: {
    SfButton,
    SfInput,
  },
  setup() {
    const {
      cart, applyCoupon, removeCoupon, error,
    } = useCart();

    const couponCodeUserInput = ref('');

    const couponCodeAppliedToCart = computed(() => cartGetters.getAppliedCoupon(cart.value)?.code);
    const isCouponCodeApplied = computed(() => couponCodeAppliedToCart.value !== undefined);
    const hasAnyError = computed(() => Object.values(error.value).some((value) => value !== null));
    const applyCouponMsg = computed<Error>(() => error.value.applyCoupon || error.value.removeCoupon || { message: '', name: 'apply-coupon' });
    const applyOrRemoveCoupon = async () => {
      const operationPromise = isCouponCodeApplied.value
        ? removeCoupon({})
        : applyCoupon({ couponCode: couponCodeUserInput.value });
      await operationPromise;
      couponCodeUserInput.value = '';
    };

    return {
      couponCodeUserInput,
      couponCodeAppliedToCart,
      isCouponCodeApplied,
      applyCouponMsg,
      applyOrRemoveCoupon,
      hasAnyError,
    };
  },
});
